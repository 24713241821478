<template>
  <div class="ntitle">{{model.title}}</div>
  <div class="ndesc" v-html="model.desc"></div>
</template>
<script setup>
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import http from '../common/bxhttp.js';
import {isNull,showMessage} from '../common/utils.js'
import {useRoute,useRouter} from 'vue-router'
let route=useRoute()
let model=reactive({
  title:"",
  desc:""
})
http.post("/art/web/articledetail",{artCode:route.query.newsCode}).then((res)=>{
    if(res.errcode=="0"){
        model.title=res.detail.art_title;
        model.desc=res.detail.art_desc;
    }else{
        showMessage(res.errmsg);
    }
})


</script>
<style scoped lang="scss">
.ntitle{
    margin:1.5rem;
    font-size:1.3rem;
    font-weight: bold;
    text-align: center;
}
.ndesc{
    margin:1rem;
    margin-left:2rem;
    margin-right:2rem;
}
</style>